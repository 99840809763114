import "./src/css/variables.css"
import "./src/css/styles.css"
import "react-toastify/dist/ReactToastify.css"
import React from "react"
import { Grommet } from "grommet"
import theme from "./src/theme"
import { ToastContainer } from "react-toastify"

export const wrapRootElement = ({ element }) => (
  <Grommet theme={theme}>
    {element}
    <ToastContainer />
  </Grommet>
)
