import { grommet } from "grommet"
import { deepMerge } from "grommet/utils"

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: "#10f6b1",
      primary: "#00d07e",
      complementary: "#ff4163",
      primaryLight: "#00f091",
      primaryAlpha05: "rgba(59,207,152,0.05)",
      primaryAlpha15: "rgba(59,207,152,0.15)",
      secondary: "#192a3e",
      secondaryLight: "#233b57",
      lightGrey: "#efefef",
      grey: "#777",
      text: "#202020",
      link: "var(  primary)",
    },
    breakpoints: {
      xsmall: { value: 320 },
      small: { value: 520 },
      medium: { value: 768 },
      large: { value: 1024 },
      xlarge: { value: 1200 },
    },
    font: {
      family: "'Montserrat', sans-serif;",
    },
  },
})

export const breakpoints = {
  xsmall: 320,
  small: 520,
  medium: 768,
  large: 1024,
  xlarge: 1200,
}

export default theme

export const mediaQuery = key => {
  const width =
    typeof theme.global.breakpoints[key] !== "undefined"
      ? theme.global.breakpoints[key].value + "px"
      : key
  return style => `@media (min-width: ${width}) { ${style} }`
}
